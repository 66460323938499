import {AppIconProps} from './AppIconProps';
import useFeatherIconProps from './useFeatherIconProps';

import React from 'react';

export default function Instagram(props: AppIconProps): JSX.Element {
  const iconProps = useFeatherIconProps(props);
  return (
    <svg
      fill="none"
      height="32"
      overflow="visible"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      {...iconProps}
    >
      <circle
        cx="16"
        cy="16"
        fill="#866B75"
        r="16"
      />
      <path
        d="M19 9H12.3333C10.4924 9 9 10.4924 9 12.3333V19C9 20.8409 10.4924 22.3333 12.3333 22.3333H19C20.8409 22.3333 22.3333 20.8409 22.3333 19V12.3333C22.3333 10.4924 20.8409 9 19 9Z"
        stroke="#ECE8EA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M18.3333 15.2467C18.4156 15.8015 18.3208 16.3682 18.0625 16.866C17.8041 17.3639 17.3954 17.7676 16.8944 18.0198C16.3934 18.272 15.8256 18.3598 15.2718 18.2707C14.7181 18.1816 14.2065 17.9201 13.8099 17.5235C13.4132 17.1269 13.1518 16.6153 13.0627 16.0615C12.9736 15.5077 13.0613 14.94 13.3135 14.439C13.5657 13.9379 13.9694 13.5292 14.4673 13.2709C14.9652 13.0125 15.5318 12.9178 16.0866 13C16.6526 13.084 17.1765 13.3477 17.5811 13.7522C17.9857 14.1568 18.2494 14.6808 18.3333 15.2467Z"
        stroke="#ECE8EA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M19.3335 12H19.341"
        stroke="#ECE8EA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
}
