import mentor from '../../../__generated__/intl/strings/mentor';
import useAppTheme from '../design_system/hooks/useAppTheme';
import Compass from '../design_system/icon/Compass';
import brown from '../design_system/theme/colors/brown';
import {ContentColor} from '../design_system/theme/useContentPalette';
import Typography, {Size} from '../design_system/typography/Typography';
import Flex from '../layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export interface MentorMarkerProps {
  isAvailable?: boolean;
}

export default function MentorMarker(props: MentorMarkerProps): JSX.Element {
  const {isAvailable} = props;
  const {palettes, spacing} = useAppTheme();
  return (
    <Flex
      sx={css({
        display: 'inline-flex',
        backgroundColor: isAvailable
          ? palettes.background.accent.default
          : brown.brown300,
        columnGap: spacing.x4,
        borderRadius: '3px',
        boxSizing: 'border-box',
        padding: spacing.x4,
      })}
    >
      <Compass
        color={
          isAvailable
            ? ContentColor.PRIMARY_CONTRAST
            : ContentColor.PRIMARY_SUBDUED
        }
        size={16}
      />

      <Typography
        color={
          isAvailable
            ? ContentColor.PRIMARY_CONTRAST
            : ContentColor.PRIMARY_SUBDUED
        }
        size={Size.XS}
      >
        {mentor()}
      </Typography>
    </Flex>
  );
}
