import {SNSAccount} from './UserPublicProfile_UserFragment';
import facebook from '../../../../__generated__/intl/strings/facebook';
import github from '../../../../__generated__/intl/strings/github';
import instagram from '../../../../__generated__/intl/strings/instagram';
import linkedin from '../../../../__generated__/intl/strings/linkedin';
import twitter from '../../../../__generated__/intl/strings/twitter';
import {CSSStyle} from '../../../types/CSSStyles';
import IconButton from '../../design_system/Button/IconButton';
import {ButtonColor} from '../../design_system/Button/options';
import {AppIconProps} from '../../design_system/icon/AppIconProps';
import Facebook from '../../design_system/icon/Facebook';
import Github from '../../design_system/icon/Github';
import Instagram from '../../design_system/icon/Instagram';
import Linkedin from '../../design_system/icon/Linkedin';
import Twitter from '../../design_system/icon/Twitter';
import {ContentColor} from '../../design_system/theme/useContentPalette';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly data: SNSAccount;
}

export default function SNSIconLink(props: Props): JSX.Element {
  const {data} = props;

  const SOCIAL_BUTTON_STYLE: CSSStyle = css({
    borderRadius: '50%',
    height: 32,
    width: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
  });
  const iconColor = ContentColor.PRIMARY_SUBDUED;

  let icon: React.ReactElement<AppIconProps>;
  let label: string;
  switch (data.type) {
    case 'facebook':
      icon = <Facebook color={ContentColor.PRIMARY_CONTRAST} />;
      label = facebook();
      break;
    case 'instagram':
      icon = <Instagram color={iconColor} />;
      label = instagram();
      break;
    case 'twitter':
      icon = <Twitter color={iconColor} />;
      label = twitter();
      break;
    case 'linkedin':
      icon = <Linkedin color={iconColor} />;
      label = linkedin();
      break;
    case 'github':
      icon = <Github color={ContentColor.PRIMARY_CONTRAST} />;
      label = github();
      break;
  }

  return (
    <IconButton
      color={ButtonColor.TRANSPARENT}
      icon={icon}
      label={label}
      sx={SOCIAL_BUTTON_STYLE}
      target="__blank"
      to={data.url}
    />
  );
}
