import {AppIconProps} from './AppIconProps';
import useFeatherIconProps from './useFeatherIconProps';

import React from 'react';

export default function Twitter(props: AppIconProps): JSX.Element {
  const iconProps = useFeatherIconProps(props);
  return (
    <svg
      fill="none"
      height="32"
      overflow="visible"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      {...iconProps}
    >
      <circle
        cx="16"
        cy="16"
        fill="#866B75"
        r="16"
      />
      <g clipPath="url(#clip0_1688_6122)">
        <path
          d="M17.3319 14.925L22.5437 9H21.3087L16.7833 14.1446L13.1688 9H9L14.4657 16.7795L9 22.9928H10.2351L15.0141 17.5599L18.8312 22.9928H23L17.3316 14.925H17.3319ZM15.6403 16.8481L15.0865 16.0734L10.6801 9.90931H12.5772L16.1331 14.8839L16.6869 15.6586L21.3093 22.1249H19.4122L15.6403 16.8484V16.8481Z"
          fill="#ECE8EA"
          stroke="#ECE8EA"
          strokeLinejoin="round"
          strokeWidth="0.7"
        />
      </g>
      <defs>
        <clipPath id="clip0_1688_6122">
          <rect
            fill="white"
            height="14"
            transform="translate(9 9)"
            width="14"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
