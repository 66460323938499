import {UserPublicProfile_UserFragment} from './UserPublicProfile_UserFragment';
import UserPublicProfileCard from './UserPublicProfileCard';

import React from 'react';

export default function renderUserCards(
  users: UserPublicProfile_UserFragment[],
): JSX.Element[] {
  return users.map((user, i) => {
    return (
      <UserPublicProfileCard
        data={user}
        key={i}
      />
    );
  });
}
