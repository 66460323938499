import {AppIconProps} from './AppIconProps';
import useFeatherIconProps from './useFeatherIconProps';

import React from 'react';

export default function Facebook(props: AppIconProps): JSX.Element {
  const iconProps = useFeatherIconProps(props);

  return (
    <svg
      fill="none"
      height="32"
      overflow="visible"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      {...iconProps}
    >
      <circle
        cx="16"
        cy="16"
        fill="#866B75"
        r="16"
      />
      <g clipPath="url(#clip0_1688_6132)">
        <path
          d="M12.217 16.5216H13.9709V23.7419C13.9709 23.8845 14.0864 24 14.229 24H17.2027C17.3453 24 17.4608 23.8845 17.4608 23.7419V16.5556H19.477C19.6081 16.5556 19.7184 16.4572 19.7333 16.327L20.0396 13.6689C20.048 13.5957 20.0248 13.5225 19.9759 13.4676C19.9269 13.4127 19.8568 13.3813 19.7832 13.3813H17.4609V11.715C17.4609 11.2127 17.7313 10.958 18.2648 10.958C18.3408 10.958 19.7832 10.958 19.7832 10.958C19.9258 10.958 20.0413 10.8424 20.0413 10.6999V8.25997C20.0413 8.11742 19.9258 8.00191 19.7832 8.00191H17.6906C17.6758 8.00119 17.6431 8 17.5948 8C17.2317 8 15.9696 8.07128 14.9726 8.98844C13.868 10.0048 14.0215 11.2217 14.0582 11.4327V13.3812H12.217C12.0745 13.3812 11.959 13.4967 11.959 13.6393V16.2635C11.959 16.406 12.0745 16.5216 12.217 16.5216Z"
          fill="#ECE8EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_1688_6132">
          <rect
            fill="white"
            height="16"
            transform="translate(8 8)"
            width="16"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
