import SNSIconLink from './SNSIconLink';
import {UserPublicProfile_UserFragment} from './UserPublicProfile_UserFragment';
import isNonEmptyString from '../../../isNonEmptyString';
import useAppTheme from '../../design_system/hooks/useAppTheme';
import Typography, {Size} from '../../design_system/typography/Typography';
import BackgroundImageDiv from '../../Image/BackgroundImageDiv';
import Flex from '../../layout/flex/Flex';
import LanguageList from '../LanguageList';
import MentorMarker from '../MentorMarker';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly data: UserPublicProfile_UserFragment;
}

export default function UserPublicProfile(props: Props): JSX.Element {
  const {data} = props;
  const {spacing} = useAppTheme();

  const sns = data.sns ?? [];
  const showEducation = data.profession == null && data.education != null;

  return (
    <Flex
      sx={css({
        flexDirection: 'column',
        width: 340,
        rowGap: spacing.x20,
        paddingLeft: spacing.x32,
        paddingRight: spacing.x32,
      })}
    >
      <BackgroundImageDiv
        backgroundSize="cover"
        height={280}
        src={data.photoDownloadUrl ?? ''}
        sx={css({
          flex: '0 0 auto',
          borderRadius: '12px',
          display: 'flex',
          alignItems: 'flex-end',
          boxSizing: 'border-box',
          padding: spacing.x20,
        })}
        width={280}
      >
        {data.isOneOneMentor ? <MentorMarker isAvailable={true} /> : <div />}

        <div css={css({flex: '1 1 auto'})} />

        <LanguageList languages={data.language} />
      </BackgroundImageDiv>

      <Flex
        direction="column"
        sx={css({})}
      >
        <Typography
          size={Size.L}
          weight={700}
        >
          <span>{data.firstName}</span>
          <span> </span>
          <span>{data.lastName}</span>
        </Typography>

        {data.profession != null && (
          <Typography
            css={css({textAlign: 'center'})}
            size={Size.S}
          >
            <span>{data.profession.title}</span>
            <span> | </span>
            <span>{data.profession.company}</span>
          </Typography>
        )}

        {showEducation && data.education != null && (
          <Typography size={Size.S}>
            <span>{data.education.major}</span>
            <span> | </span>
            <span>{data.education.name}</span>
          </Typography>
        )}

        <Flex
          sx={css({
            height: 32,
            marginTop: spacing.x12,
            columnGap: spacing.x8,
          })}
        >
          {sns.map((account, i) => {
            return (
              <SNSIconLink
                data={account}
                key={i}
              />
            );
          })}
        </Flex>

        {isNonEmptyString(data.intro?.message) && (
          <Typography
            css={css({
              textAlign: 'center',
              marginTop: spacing.x16,
            })}
            size={Size.L}
          >
            {data.intro.message}
          </Typography>
        )}
      </Flex>
    </Flex>
  );
}
